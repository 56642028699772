html {
	font-family:
		Nunito Sans,
		ui-sans-serif,
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Helvetica Neue,
		Arial,
		Noto Sans,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		Segoe UI Symbol,
		'Noto Color Emoji';
}
/* nunito-sans-200 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 200;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-200italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 200;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 300;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 300;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-regular - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-regular.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-regular.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 400;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 600;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 600;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 700;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 700;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 800;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 800;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 900;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 900;
	src:
		url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900italic.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900italic.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
